import React, { useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import Head from 'next/head';
import CoreLayout from '../layouts/CoreLayout';
import Home from '../components/Home';
import { HOME_FEEDBACK_URL } from '../constants/globals';
import { initView } from '../actions/viewActions';
import { getFeedbackRatingAvg } from '../helpers/helperFunctions';
import * as selectors from '../selectors/selectors';

export async function getServerSideProps() {
  const { data: feedback } = await axios.get(HOME_FEEDBACK_URL);
  return {
    props: { feedback },
  };
}

function Index({ feedback }) {
  const { pathname } = useRouter();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initView(pathname));
  }, []);
  const initializingView = useSelector(selectors.initializingView);
  if (initializingView) return false;
  const rating = getFeedbackRatingAvg(feedback);
  return (
    <CoreLayout>
      <Head>
        <title>Häävalokuvaajat: Löydä kuvaaja häihisi</title>
        {feedback.length && (
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org/",
                "@type": "Product",
                "image": "https://www.xn--hvalokuvaajat-bfba.fi/static/img/logo.svg",
                "name": "häävalokuvaajat.fi",
                "description": "Häävalokuvaajat: Löydä kuvaaja häihisi",
                "aggregateRating": {
                  "@type": "aggregateRating",
                  "ratingValue": "${rating}",
                  "bestRating": "5",
                  "reviewCount": "${feedback.length}"
                }
              }
            `}
          </script>
        )}
      </Head>
      <Home feedback={feedback} />
    </CoreLayout>
  );
}

Index.propTypes = {
  feedback: PropTypes.instanceOf(Object).isRequired,
};

export default Index;
