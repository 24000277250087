import React from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import { CSSTransition } from 'react-transition-group';
import { HEROTYPE_HOME } from '../constants/globals';
import Hero from './Hero';
import HomeFeedback from './HomeFeedback';

function Home({ feedback }) {
  return (
    <main id="top">
      <CSSTransition classNames="fade-in-out-500" appear timeout={500}>
        <Hero
          heading="Löydä kuvaaja häihisi"
          intro="Tar&shy;jous&shy;pyyn&shy;nön luo&shy;mi&shy;nen on il&shy;ma&shy;is&shy;ta ei&shy;kä vaa&shy;di re&shy;kis&shy;te&shy;röi&shy;ty&shy;mis&shy;tä. Am&shy;mat&shy;ti&shy;lai&shy;set o&shy;dot&shy;ta&shy;vat yh&shy;tey&shy;den&shy;ot&shy;to&shy;asi."
          ctaText="Luo tarjouspyyntö"
          heroType={HEROTYPE_HOME}
        />
      </CSSTransition>
      <div className="info" id="info">
        <div className="info__container">
          <div className="info__top">
            <div className="info__top-content">
              <p className="info__text info__text--top">
                häävalokuvaajat.fi on uusi, ilmainen palvelu häävalokuvaajan
                löytämiseksi.
              </p>
            </div>
          </div>
          <div className="info__content">
            <div className="info__item">
              <h2 className="info__heading">Helppo ja nopea</h2>
              <p className="info__text">
                Häävalokuvaajien avulla pyydät kuvaustarjoukset kaikilta
                kuvaajiltamme yhdellä web-lomakkeella - rekisteröitymättä.
                Kuvaajilla on viikko aikaa jättää tarjous, jonka jälkeen voit
                valita sopivimman yhdellä klikkauksella.
              </p>
            </div>
            <div className="info__item">
              <h2 className="info__heading">Luotettava</h2>
              <p className="info__text">
                Kaikki kuvaajamme ovat kokeneita häävalokuvauksen
                am&shy;matti&shy;laisia. Tarjouksia vertaillessa näet kuvaajan
                tietojen ja portfolion lisäksi kuvaajan saaman palautteen muilta
                Hää&shy;valokuvaajien käyttäjiltä.
              </p>
            </div>
            <div className="info__item">
              <h2 className="info__heading">
                Kaikille tarpeille ja budjeteille
              </h2>
              <p className="info__text">
                Tarvitsetko vain potretteja vai koko päivän dokumentaarisen
                kuvauksen? Onko käytettävissä kengännauhabudjetti vai hieman
                enemmän? Tarjouspyyntöä luodessa voit kertoa tarpeistasi,
                jolloin kuvaajamme tietävät millaisia tarjouksia odotat saavasi.
              </p>
            </div>
            <div className="info__item">
              <h2 className="info__heading">Ei velvoita mihinkään</h2>
              <p className="info__text">
                Tarjoukset saatuasi sinulla ei ole velvoitetta hyväksyä yhtään
                tarjousta. Voit jättää tarjouspyynnön sikseen - tai halutessasi
                luoda uuden tarjouspyynnön.
              </p>
            </div>
          </div>
          <div className="info__bottom">
            <div className="info__bottom-content">
              <button
                type="button"
                className="info__text info__text--bottom"
                onClick={() => {
                  const info = document.getElementById('top');
                  info && info.scrollIntoView(); // eslint-disable-line
                }}
              >
                Luo ilmainen tarjouspyyntö
              </button>
            </div>
          </div>
        </div>
      </div>
      <HomeFeedback feedback={feedback} />
      <div className="how">
        <div className="how__container">
          <div className="how__top">
            <h2 className="how__heading">Näin se toimii</h2>
            <img
              className="how__img"
              src="/static/img/flower.svg"
              alt="Näin se toimii"
            />
          </div>
          <div className="how__content">
            <div className="how__item">
              <h2 className="how__number">1</h2>
              <h2 className="how__heading how__heading--top">Tarjouspyyntö</h2>
              <p className="how__text">
                Luotuasi tarjouspyynnön, välitämme sen kaikille kuvaajillemme.
                Voit rauhassa odotella tarjousten saapumista.
              </p>
            </div>
            <div className="how__item">
              <h2 className="how__number">2</h2>
              <h2 className="how__heading how__heading--top">Tarjoukset</h2>
              <p className="how__text">
                Saat uudet tarjoukset sähköpostiisi sekä linkin
                tarjousvertailuun, jossa näet kuvaajien profiilit ja heidän
                saamansa palautteet. Voit yhdellä klikkauksella valita
                tarjouksista heti sen saavuttua sopivimman.
              </p>
            </div>
            <div className="how__item">
              <h2 className="how__number">3</h2>
              <h2 className="how__heading how__heading--top">
                Hääkuvaajan valinta
              </h2>
              <p className="how__text">
                Valittuasi sopivimman tarjouksen, hääkuvaaja ottaa sinuun
                yhteyttä sopiakseen tarkemmin kuvauksesta — ja suuren päivänne
                koittaessa hän kuvaa häät sopimanne mukaisesti.
              </p>
              <p className="how__text how__text--free">
                Palvelu on sinulle ilmainen.
              </p>
            </div>
          </div>
          <div className="how__bottom">
            <div className="how__bottom-content">
              <button
                type="button"
                className="info__text info__text--bottom"
                onClick={() => {
                  const info = document.getElementById('top');
                  info && info.scrollIntoView(); // eslint-disable-line
                }}
              >
                Luo ilmainen tarjouspyyntö
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="cost-info">
        <div className="cost-info__container">
          <div className="cost-info__top">
            <p className="cost-info__text--top">
              Artikkeleita ja tietoa häävalokuvauksesta
            </p>
          </div>
          <div className="cost-info__content">
            <div className="cost-info__item">
              <h2 className="cost-info__heading">Mitä häävalokuvaus maksaa?</h2>
              <p className="cost-info__text">
                Artikkelimme aiheesta ottaa huomioon tarpeesi ja kertoo mitä
                häävalokuvaus maksaa <i>sinulle</i>.
              </p>
            </div>
          </div>
          <div className="cost-info__bottom">
            <div className="cost-info__bottom-content">
              <button
                type="button"
                className="cost-info__text cost-info__text--bottom"
                onMouseOver={() => Router.prefetch('/kuvauksen-hinta')}
                onFocus={() => Router.prefetch('/kuvauksen-hinta')}
                onClick={() => {
                  Router.push('/kuvauksen-hinta');
                }}
              >
                Häävalokuvauksen hinta
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="shooter-info">
        <div className="shooter-info__container">
          <div className="shooter-info__top">
            <p className="shooter-info__text shooter-info__text--top">
              Oletko häävalokuvaaja?
            </p>
          </div>
          <div className="shooter-info__content">
            <div className="shooter-info__item">
              <h2 className="shooter-info__heading">Liity jäseneksi</h2>
              <p className="shooter-info__text">
                Palvelun jäsenyys takaa mahdollisuuden tarjota kaikkiin
                häävalokuvauspyyntöihimme. Hallinnoit määrämuotoisia
                tarjouspyyntöjä hallintapaneelissa, josta käsin voit helposti
                lähettää tarjoukset.
              </p>
            </div>
          </div>
          <div className="shooter-info__bottom">
            <div className="shooter-info__bottom-content">
              <button
                type="button"
                className="shooter-info__text shooter-info__text--bottom"
                onMouseOver={() => Router.prefetch('/join')}
                onFocus={() => Router.prefetch('/join')}
                onClick={() => {
                  Router.push('/liity');
                }}
              >
                Haluan tietää enemmän
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

Home.propTypes = {
  feedback: PropTypes.instanceOf(Object).isRequired,
};

export default Home;
