import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import StarRatings from 'react-star-ratings';
import { Carousel } from 'react-responsive-carousel';

function truncate(str, n = 320) {
  if (str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1); // the original check
  return `${subString.substr(0, subString.lastIndexOf(' '))} (⋯)`;
}

function HomeFeedback({ feedback }) {
  return (
    <div className="feedback">
      <div className="feedback__container">
        <div className="feedback__top">
          <h2 className="feedback__heading">Kuvaajiemme saamia palautteita</h2>
          <img
            className="feedback__img"
            src="/static/img/flower.svg"
            alt="Kuvaajiemme saamia palautteita"
          />
        </div>
        <Carousel
          autoPlay
          showStatus={false}
          showArrows={false}
          infiniteLoop
          showIndicators={false}
          showThumbs={false}
          interval={4000}
        >
          {feedback.map(({ names, description, rating }) => (
            <div key={names}>
              <StarRatings
                rating={rating}
                numberOfStars={rating}
                name="rating"
                starRatedColor="#fff"
                starDimension="4rem"
                starSpacing="0.4rem"
              />
              <blockquote className="feedback__text">
                {truncate(description)}
              </blockquote>
              <small className="feedback__author">― {names}</small>
            </div>
          ))}
        </Carousel>
        <Link href="/shooters" as="/kuvaajat">
          <a>
            <button type="button" className="feedback__shooters">
              Tutustu kuvaajiimme
            </button>
          </a>
        </Link>
      </div>
    </div>
  );
}

HomeFeedback.propTypes = {
  feedback: PropTypes.instanceOf(Object).isRequired,
};

export default HomeFeedback;
